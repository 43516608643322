// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CanvasComponent{
    /* position: absolute;
    background-color: lightcoral;
    display: inline-block;
    border-radius: 10px;
    transform: scale(0.4);

    left: calc(50% - 580px);
    top: -480px;  */

    /* background-color: lightcoral; */
    height: calc(100vh - 100px);
    width: calc(100% - 650px);
    margin-top: 75px;
    margin-left: 150px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/Components/CanvasComponent/CanvasComponent.css"],"names":[],"mappings":"AAAA;IACI;;;;;;;mBAOe;;IAEf,kCAAkC;IAClC,2BAA2B;IAC3B,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".CanvasComponent{\n    /* position: absolute;\n    background-color: lightcoral;\n    display: inline-block;\n    border-radius: 10px;\n    transform: scale(0.4);\n\n    left: calc(50% - 580px);\n    top: -480px;  */\n\n    /* background-color: lightcoral; */\n    height: calc(100vh - 100px);\n    width: calc(100% - 650px);\n    margin-top: 75px;\n    margin-left: 150px;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
