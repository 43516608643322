import { createSlice } from "@reduxjs/toolkit";


const canvasSlice = createSlice(
 {  
    name: 'canvas',
    initialState: {
        height: 1920,
        width: 1080,
    },
    reducers: {
        setCanvasHeight(state, action) {
            state.height = action.payload;
        },
        setCanvasWidth(state, action) {
            state.width = action.payload;
        },
    }
}
);

export const canvasActions = canvasSlice.actions;

export default canvasSlice;