import React, { useEffect, useState } from 'react';
import CanvasFunctions from '../../HelperFunctions/CanvasFunctions';
import FontPicker from 'react-fontpicker-ts';

function FontFamilyItem({ currentElement }) {
    const [font, setFont] = useState('Amita');

    useEffect(() => {
        if (currentElement && currentElement.fontFamily) {
            setFont(currentElement.fontFamily);
        }
    }, []);

    function onchangeHandler(newFont) {
        const functions = new CanvasFunctions();
        functions.setFontFamily(newFont);
        setFont(newFont);
    }

    return (
        <div className="OpacityItemHolder" style={{ width: '240px' }}>
            <div className="OpacityItemTitle">Font Family:</div>
            <div className="OpacityItem" style={{width: "150px", top: "0px"}}>
                <FontPicker
                    defaultValue={'Amita'}
                    autoLoad
                    value={(newFont) => onchangeHandler(newFont)}
                />
                <div style={{fontFamily: font, color: "white", opacity: 0}}>.</div>
            </div>
        </div>
    );
}

export default FontFamilyItem;
