import React from 'react';
import CanvasFunctions from '../../HelperFunctions/CanvasFunctions';

function CircledImageItem({ checked, setChecked }) {
    function onchangeHandler() {
        const functions = new CanvasFunctions();
        functions.setIsCircle(!checked);
        setChecked(!checked);
    }

    return (
        <div className="OpacityItemHolder" style={{ width: '80px' }}>
            <input
                type="checkbox"
                className="OpacityItem"
                checked={checked}
                onChange={onchangeHandler}
            />
            <div className="OpacityItemTitle">Circle:</div>
        </div>
    );
}

export default CircledImageItem;
