// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementTile{
    height: 30px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px gray solid;
    text-align: start;
    padding-left: 12px;
    padding-top: 3px;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/Components/ElementsBar/ElementTile/ElementTile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oCAAoC;IACpC,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".ElementTile{\n    height: 30px;\n    background-color: rgb(255, 255, 255);\n    border-bottom: 1px gray solid;\n    text-align: start;\n    padding-left: 12px;\n    padding-top: 3px;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
