// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsBar{
    display: inline-block;
    position: absolute;
    left: 0%;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    padding-top: 0px;
    box-sizing: border-box;
    padding-left: 20px;
    text-align: start;  
}

`, "",{"version":3,"sources":["webpack://./src/Components/SettingsBar/SettingsBar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".SettingsBar{\n    display: inline-block;\n    position: absolute;\n    left: 0%;\n    width: 80%;\n    height: 50px;\n    background-color: rgb(255, 255, 255);\n    padding-top: 0px;\n    box-sizing: border-box;\n    padding-left: 20px;\n    text-align: start;  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
