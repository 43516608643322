// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageItemOutline{
    height: 50px;
    width: 50px;
    background-color: rgb(5, 32, 102);
    text-align: center;
    border: 1px solid gray;
    padding-top: 10px;
    box-sizing: border-box;
    position: relative;
}

.ImageItem{
    left: 0;
    opacity: 0;
    
    
    
    position: absolute;
    z-index: 2;
}

.ImageIcon{
    position: absolute;
    top: 13px;
    left: 15px;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomTools/ImageItem/ImageItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iCAAiC;IACjC,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,OAAO;IACP,UAAU;;;;IAIV,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,UAAU;AACd","sourcesContent":[".ImageItemOutline{\n    height: 50px;\n    width: 50px;\n    background-color: rgb(5, 32, 102);\n    text-align: center;\n    border: 1px solid gray;\n    padding-top: 10px;\n    box-sizing: border-box;\n    position: relative;\n}\n\n.ImageItem{\n    left: 0;\n    opacity: 0;\n    \n    \n    \n    position: absolute;\n    z-index: 2;\n}\n\n.ImageIcon{\n    position: absolute;\n    top: 13px;\n    left: 15px;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
