// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    overflow: hidden;
}

.fontpicker {
    background: #fff !important;
}

.fontpicker__preview {
    filter: none !important;
}

.fontpicker__search {
    background: #fff !important;
}

.fontpicker__popout {
    background: #fff !important;
}

.fontpicker__option {
    background: #fff !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".App {\n    text-align: center;\n    overflow: hidden;\n}\n\n.fontpicker {\n    background: #fff !important;\n}\n\n.fontpicker__preview {\n    filter: none !important;\n}\n\n.fontpicker__search {\n    background: #fff !important;\n}\n\n.fontpicker__popout {\n    background: #fff !important;\n}\n\n.fontpicker__option {\n    background: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
