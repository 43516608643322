// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolBarComponent{
    width: 50px;
    border-radius: 10px;
    background-color: lightblue;
    display: inline-block;
    position: absolute;
    top: 20%;
    left: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/ToolBarComponent/ToolBarComponent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,qBAAqB;IACrB,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd","sourcesContent":[".ToolBarComponent{\n    width: 50px;\n    border-radius: 10px;\n    background-color: lightblue;\n    display: inline-block;\n    position: absolute;\n    top: 20%;\n    left: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
