import React, { useEffect, useState } from 'react';
import CanvasFunctions from '../../HelperFunctions/CanvasFunctions';

function BorderRadiusItem({ currentElement, checked }) {
    const [currentValue, setCurrentValue] = useState(0);

    useEffect(() => {
        setCurrentValue(currentElement.borderRadius);
    }, [currentElement]);

    function onchangeHandler(e) {
        const functions = new CanvasFunctions();
        functions.setBorderRadius(e.target.value);
        setCurrentValue(e.target.value);
    }

    return (
        <div className="OpacityItemHolder" style={{width: "160px"}}>
            <div className="OpacityItemTitle"> Border Radius:</div>
            <input
                className="OpacityItem"
                type="number"
                min="0"
                step={1}
                value={currentValue}
                onChange={onchangeHandler}
                disabled={checked}
            />
        </div>
    );
}

export default BorderRadiusItem;
