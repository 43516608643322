// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementsBarTopArea{
    width: 100%;
    height: 40px;
    background-color: rgb(227, 227, 227);
    padding-top: 5px;
    box-sizing: border-box;
    text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/Components/ElementsBar/ElementsBarTopArea/ElementsBarTopArea.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".ElementsBarTopArea{\n    width: 100%;\n    height: 40px;\n    background-color: rgb(227, 227, 227);\n    padding-top: 5px;\n    box-sizing: border-box;\n    text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
