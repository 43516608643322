// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolBarItem{
    height: 50px;
    background-color: rgb(5, 32, 102);
    text-align: center;
    border: 1px solid gray;
    padding-top: 10px;
    box-sizing: border-box;
}

`, "",{"version":3,"sources":["webpack://./src/Components/ToolBarItem/ToolBarItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".ToolBarItem{\n    height: 50px;\n    background-color: rgb(5, 32, 102);\n    text-align: center;\n    border: 1px solid gray;\n    padding-top: 10px;\n    box-sizing: border-box;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
