// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextSizeItemHolder{
    margin-left: 1cqw;
    margin-top: 10px;
    position: relative;
    width: 115px;
    height: 40px;
    display: inline-block;
    background-color: rgba(0, 0, 255, 0);
}

.TextSizeItemTitle{
    position: absolute;
    left: 0;
    top: 5px;
    height: 16px;
    display: inline-block;  
}

.TextSizeItem{
    position: absolute;
    right: 0 ;
    top: 5px;
    width: 30px;
    height: 16px;
    display: inline-block;  
}`, "",{"version":3,"sources":["webpack://./src/Components/SettingsItems/TextSizeItem/TextSizeItem.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":[".TextSizeItemHolder{\n    margin-left: 1cqw;\n    margin-top: 10px;\n    position: relative;\n    width: 115px;\n    height: 40px;\n    display: inline-block;\n    background-color: rgba(0, 0, 255, 0);\n}\n\n.TextSizeItemTitle{\n    position: absolute;\n    left: 0;\n    top: 5px;\n    height: 16px;\n    display: inline-block;  \n}\n\n.TextSizeItem{\n    position: absolute;\n    right: 0 ;\n    top: 5px;\n    width: 30px;\n    height: 16px;\n    display: inline-block;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
