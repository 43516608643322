// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementsBarTopAreaButton{
    height: 30px;
    width: 30px;
    background-color:  rgb(5, 32, 102);
    display: inline-block;
    margin-left: 4px;
    text-align: center;
    padding-top: 4px;
    border-radius: 6px;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/Components/ElementsBar/ElementsBarTopArea/ElemenBarTopAreaButton/ElementsBarTopAreaButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kCAAkC;IAClC,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".ElementsBarTopAreaButton{\n    height: 30px;\n    width: 30px;\n    background-color:  rgb(5, 32, 102);\n    display: inline-block;\n    margin-left: 4px;\n    text-align: center;\n    padding-top: 4px;\n    border-radius: 6px;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
