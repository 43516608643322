// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementsBar{
    position: absolute;
    display: inline-block;
    background-color: rgb(207, 206, 206);
    width: 350px;
    height: 100vh;
    right: 0;
    top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ElementsBar/ElementsBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,QAAQ;IACR,SAAS;AACb","sourcesContent":[".ElementsBar{\n    position: absolute;\n    display: inline-block;\n    background-color: rgb(207, 206, 206);\n    width: 350px;\n    height: 100vh;\n    right: 0;\n    top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
