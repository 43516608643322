// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SaveButton{
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    background-color: rgb(3, 184, 3);
    padding: 5px 20px;
    color: white;
    border-radius: 10%;
    font-size: large;
    font-weight: 500;
    -webkit-user-select: none;
            user-select: none;
}
.SaveButtonOff{
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    background-color: rgb(190, 210, 190);
    padding: 5px 20px;
    color: white;
    border-radius: 10%;
    font-size: large;
    font-weight: 500;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/SaveButton/SaveButton.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":["\n.SaveButton{\n    display: inline-block;\n    position: absolute;\n    right: 20px;\n    bottom: 10px;\n    background-color: rgb(3, 184, 3);\n    padding: 5px 20px;\n    color: white;\n    border-radius: 10%;\n    font-size: large;\n    font-weight: 500;\n    user-select: none;\n}\n.SaveButtonOff{\n    display: inline-block;\n    position: absolute;\n    right: 20px;\n    bottom: 10px;\n    background-color: rgb(190, 210, 190);\n    padding: 5px 20px;\n    color: white;\n    border-radius: 10%;\n    font-size: large;\n    font-weight: 500;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
