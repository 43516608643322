import { useDispatch } from "react-redux";
import CanvasComponent from "../../Components/CanvasComponent/CanvasComponent";
import ElementsBar from "../../Components/ElementsBar/ElementsBar";
import ToolBarComponent from "../../Components/ToolBarComponent/ToolBarComponent";
import TopBarComponent from "../../Components/TopBarComponent/TopBarComponent";
import { getJsonTemplate } from "../../Services/apiService";
import { canvasActions } from "../../store/canvas-store";
import "./DesignPage.css";
import {useSearchParams } from 'react-router-dom';

function DesignPage(){
    
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    let query = searchParams.get("paylasimKodu");
    let width = searchParams.get("x");
    let height = searchParams.get("y");
    if(query){
        getJsonTemplate(query);
        
        if(width && height){
            dispatch(canvasActions.setCanvasHeight(Number(height)));
            dispatch(canvasActions.setCanvasWidth(Number(width)));
        }
    }
    

    return <div className="DesignPage">
        <TopBarComponent designCode={query}/>
        <ToolBarComponent/>
        <CanvasComponent/>
        <ElementsBar/>
    </div>;
}

export default DesignPage;