// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParametricJsonOutputArea{
    background-color: rgb(28, 79, 124);
    height: 90vh;
    width: 350px;
    display: inline-block;
    position: absolute;
    color: greenyellow;
    right: 0;
    top: 40px;
    padding-top: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    text-align: start;
    white-space: pre-wrap;
    overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Components/ParametricJsonOutputArea/ParametricJsonOutputArea.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[".ParametricJsonOutputArea{\n    background-color: rgb(28, 79, 124);\n    height: 90vh;\n    width: 350px;\n    display: inline-block;\n    position: absolute;\n    color: greenyellow;\n    right: 0;\n    top: 40px;\n    padding-top: 15px;\n    padding-left: 15px;\n    box-sizing: border-box;\n    text-align: start;\n    white-space: pre-wrap;\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
