// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DesignPage{
    background: rgb(223, 223, 223);
    background-image: radial-gradient(black 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
    height: 100vh;
    width: 100vw;
    align-items: center;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Pages/DesignPage/DesignPage.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,2DAA2D;IAC3D,0BAA0B;IAC1B,gCAAgC;IAChC,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".DesignPage{\n    background: rgb(223, 223, 223);\n    background-image: radial-gradient(black 1px, transparent 0);\n    background-size: 40px 40px;\n    background-position: -19px -19px;\n    height: 100vh;\n    width: 100vw;\n    align-items: center;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
