import './CanvasComponent.css';
import { fabric } from 'fabric';
import { useEffect, useState } from 'react';
import CanvasFunctions from '../../HelperFunctions/CanvasFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { elementsBarActions } from '../../store/elements-bar-store';

const snapZone = 15;

function CanvasComponent(props) {
    const canvasHeight = useSelector((state) => state.canvas.height);
    const canvasWidth = useSelector((state) => state.canvas.width);
    const dispatch = useDispatch();

    useEffect(() => {
        const canvas = new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            selection: false,
        });
        const canvasFunctions = new CanvasFunctions(canvas, dispatch);

        const lineX = new fabric.Line(
            [canvas.width / 2, 0, canvas.width / 2, canvas.height],
            {
                stroke: 'red',
            }
        );

        lineX.selectable = false;
        lineX.evented = false;
        lineX.id = 'lineX';
        canvas.add(lineX);

        const lineY = new fabric.Line(
            [0, canvas.height / 2, canvas.width, canvas.height / 2],
            {
                stroke: 'red',
            }
        );

        lineY.selectable = false;
        lineY.evented = false;
        lineY.id = 'lineY';
        canvas.add(lineY);

        lineY.set({ visible: false });
        lineX.set({ visible: false });

        canvas.backgroundColor = '#ffffffff';
        canvasFunctions.renderAll();

        canvas.on('object:scaling', function (e) {
            dispatch(elementsBarActions.setLastRefreshTime(Date.now()));
            var target = e.target;

            if (!target.id.includes('Alpha')) {
                return;
            }
        });


        window.addEventListener(
            'keydown',
            (event) => {
                console.log(event);
                if (event.defaultPrevented) {
                    return;
                }

                let handled = false;
                if (event.key === 'Delete' || event.code === 'Backquote') {
                    canvasFunctions.removeActiveElement();
                    handled = true;
                }

                if (handled) {
                    event.preventDefault();
                }
            },
            true
        );

        canvas.on({
            'selection:updated': setSelectedElement,
            'selection:created': setSelectedElement,
        });
        canvas.on({
            'object:added': setAddedElement,
        });

        function setSelectedElement(obj) {
            dispatch(elementsBarActions.setCurrentElement(obj.selected[0]));
            linesBringToFront();
        }
        function setAddedElement(obj) {
            dispatch(elementsBarActions.setCurrentElement(obj.target));
            linesBringToFront();
        }
        function linesBringToFront() {
            canvas.bringToFront(lineX);
            canvas.bringToFront(lineY);
        }

        canvas.on('object:moving', (options) => {
            dispatch(elementsBarActions.setLastRefreshTime(Date.now()));

            const middleX = options.target.left + (options.target.width * options.target.scaleX) / 2;
            const middleY = options.target.top + (options.target.height * options.target.scaleY) / 2;

            if (middleX > canvas.width / 2 - snapZone && middleX < canvas.width / 2 + snapZone) {
                lineX.set({ visible: true });
                options.target.set({left: canvas.width / 2 - (options.target.width * options.target.scaleX) / 2}).setCoords();
            } else {
                lineX.set({ visible: false });
            }
            
            if (middleY > canvas.height / 2 - snapZone && middleY < canvas.height / 2 + snapZone) {
                lineY.set({ visible: true });
                options.target.set({ top: canvas.height / 2 - (options.target.height * options.target.scaleY) / 2 }).setCoords();
            } else {
                lineY.set({ visible: false });
            }
        });

        canvas.on('mouse:up', () => {
            lineY.set({ visible: false });
            lineX.set({ visible: false });
        });

        // eslint-disable-next-line
    }, []);

    const point = 1920 * 0.4;
    const constScale = point / canvasHeight
    // const [scale, setScale] = useState(constScale);

    // const handleWheel = (e) => {
    //     e.preventDefault();
    //     const scaleFactor = e.deltaY > 0 ? 0.98 : 1.02;
    //     setScale((prevScale) => prevScale * scaleFactor);
    // };

    return (
        <div 
            className="CanvasComponent" 
            // onWheel={handleWheel}
        > 
            <canvas 
                id="canvas" 
                width={canvasWidth} 
                height={canvasHeight} 
                style={{ transform: `scale(${constScale})`, transformOrigin: "top center", }}
            >
            </canvas>
        </div>
    );
}

export default CanvasComponent;
