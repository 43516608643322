// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBarComponent{
    height: 50px;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    display: inline-block;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0px;
    border-radius: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/TopBarComponent/TopBarComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,oCAAoC;IACpC,qBAAqB;IACrB,eAAe;IACf,UAAU;IACV,MAAM;IACN,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".TopBarComponent{\n    height: 50px;\n    width: 100vw;\n    background-color: rgb(255, 255, 255);\n    display: inline-block;\n    position: fixed;\n    z-index: 1;\n    top: 0;\n    left: 0px;\n    border-radius: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
